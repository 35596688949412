import { Box, Grid, useMediaQuery } from '@mui/material'
import { Button } from 'antd'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import React, { useLayoutEffect } from 'react'
import { Footer, Header, SEO, useI18next } from '../../components'
import Title from '../../components/Title'

export default (props) => {
  const {} = useTranslation()
  const { t, languages, changeLanguage, language } = useI18next()
  const pageLocale = JSON.parse(props.data.locales.edges[0].node.data)
  const seoLocale = JSON.parse(props.data.seoLocale.data)
  const isPc = useMediaQuery('@media(min-width: 800px)')
  const { navigate } = useI18next()

  useLayoutEffect(() => {
    document.querySelector('.banner')?.scrollIntoView({})
  }, [])

  const Movement = () => {
    return (
      <Grid
        container
        spacing={{ xs: '20px', sm: '20px', md: '20px', lg: '20px', xl: '40px' }}
        columns={{ xs: 4, sm: 8, md: 12 }}
        sx={{
          marginBottom: '70px',
          backgroundColor: ['#fff'],
        }}
      >
        {pageLocale.LogisticsProducts.map((item, index) => {
          return (
            <Grid item xs={isPc ? 3 : 12} sm={isPc ? 3 : 12} md={isPc ? 3 : 12} key={index}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  borderRadius: ['0 0 20px 20px', '0 0 20px 20px', '24px'],
                  padding: ['0', '0', '8px'],
                  backgroundColor: ['#F6F7FA', '#F6F7FA', '#fff'],
                  position: 'relative',
                  top: '0',
                  transition: '.4s',
                  cursor: 'pointer',
                  '&:hover': {
                    top: isPc ? '-4px' : '0px',
                    boxShadow: ['none', 'none', '0px 0px 10px 0px rgba(0, 0, 0, 0.1)'],
                  },
                  '.bg-img1': {
                    height: ['250px', '310px', '220px'],
                    transition: 'height 0.5s linear',
                    objectFit: 'fill',
                  },
                  '.country': {
                    display: 'flex',
                    alignItems: 'center',
                    padding: ['20px 16px 0', '26px 16px 0'],
                    '.country-img': {
                      width: ['40px', '66px'],
                      margin: ['-6px', '-8px'],
                    },
                    '.country-name': {
                      fontWeight: 600,
                      fontSize: ['18px', '24px'],
                      lineHeight: ['24px', '34px'],
                      color: '#000417',
                      marginInlineStart: ['8px', '12px'],
                    },
                  },
                  '.country-desc': {
                    color: '#5B5C73',
                    fontSize: '15px',
                    whiteSpace: 'pre-line',
                    padding: ['0 16px', '0 20px'],
                    lineHeight: ['22px', '28px'],
                    margin: ['16px 0 5px 0', '8px 0 12px 0'],
                  },
                  '.read-btn': {
                    padding: ['0 16px 20px', '0 20px 16px'],
                    lineHeight: ['20px', '24px'],
                    button: {
                      padding: 0,
                    },
                  },
                }}
                onClick={() => {
                  navigate('/service/logisticsProductsDetail', {
                    state: { country: item.country },
                  })
                }}
              >
                {index === 0 && (
                  <>
                    {isPc ? (
                      <StaticImage
                        src={'../../../static/images/logisticsProduct/Product-UAE.png'}
                        alt='About iMile Delivery'
                        objectFit='fill'
                        loading='lazy'
                        placeholder='blurred'
                        className='bg-img1'
                      />
                    ) : (
                      <img
                        src={
                          'https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/Product-Mobile-UAE.png'
                        }
                        alt='About iMile Delivery'
                        className='bg-img1'
                      />
                    )}
                    {/* {isPc ? (
                      <StaticImage
                        src={'../../../static/images/logisticsProduct/Product-UAE.png'}
                        alt='About iMile Delivery'
                        objectFit='fill'
                        loading='lazy'
                        placeholder='blurred'
                        className='bg-img1'
                      />
                    ) : (
                      <StaticImage
                        src={'../../../static/images/logisticsProduct/Product-Mobile-UAE.png'}
                        alt='About iMile Delivery'
                        objectFit='fill'
                        loading='lazy'
                        placeholder='blurred'
                        className='bg-img1'
                      />
                    )} */}
                  </>
                )}
                {index === 1 && (
                  <>
                    {isPc ? (
                      <StaticImage
                        src={'../../../static/images/logisticsProduct/Product-KSA.png'}
                        alt='About iMile Delivery'
                        objectFit='fill'
                        loading='lazy'
                        placeholder='blurred'
                        className='bg-img1'
                      />
                    ) : (
                      <img
                        src={
                          'https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/Product-Mobile-KSA.png'
                        }
                        alt='About iMile Delivery'
                        className='bg-img1'
                      />
                    )}
                    {/* {isPc ? (
                      <StaticImage
                        src={'../../../static/images/logisticsProduct/Product-KSA.png'}
                        alt='About iMile Delivery'
                        objectFit='fill'
                        loading='lazy'
                        placeholder='blurred'
                        className='bg-img1'
                      />
                    ) : (
                      <StaticImage
                        src={'../../../static/images/logisticsProduct/Product-Mobile-KSA.png'}
                        alt='About iMile Delivery'
                        objectFit='fill'
                        loading='lazy'
                        placeholder='blurred'
                        className='bg-img1'
                      />
                    )} */}
                  </>
                )}
                {index === 2 && (
                  <>
                    {isPc ? (
                      <StaticImage
                        src={'../../../static/images/logisticsProduct/Product-MEX.png'}
                        alt='About iMile Delivery'
                        objectFit='fill'
                        loading='lazy'
                        placeholder='blurred'
                        className='bg-img1'
                      />
                    ) : (
                      <img
                        src={
                          'https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/Product-Mobile-MEX.png'
                        }
                        alt='About iMile Delivery'
                        className='bg-img1'
                      />
                    )}
                    {/* {isPc ? (
                      <StaticImage
                        src={'../../../static/images/logisticsProduct/Product-MEX.png'}
                        alt='About iMile Delivery'
                        objectFit='fill'
                        loading='lazy'
                        placeholder='blurred'
                        className='bg-img1'
                      />
                    ) : (
                      <StaticImage
                        src={'../../../static/images/logisticsProduct/Product-Mobile-MEX.png'}
                        alt='About iMile Delivery'
                        objectFit='fill'
                        loading='lazy'
                        placeholder='blurred'
                        className='bg-img1'
                      />
                    )} */}
                  </>
                )}
                {index === 3 && (
                  <>
                    {isPc ? (
                      <StaticImage
                        src={'../../../static/images/logisticsProduct/Product-JOR.png'}
                        alt='About iMile Delivery'
                        objectFit='fill'
                        loading='lazy'
                        placeholder='blurred'
                        className='bg-img1'
                      />
                    ) : (
                      <img
                        src={
                          'https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/Product-Mobile-JOR.png'
                        }
                        alt='About iMile Delivery'
                        className='bg-img1'
                      />
                    )}
                    {/* {isPc ? (
                      <StaticImage
                        src={'../../../static/images/logisticsProduct/Product-JOR.png'}
                        alt='About iMile Delivery'
                        objectFit='fill'
                        loading='lazy'
                        placeholder='blurred'
                        className='bg-img1'
                      />
                    ) : (
                      <StaticImage
                        src={'../../../static/images/logisticsProduct/Product-Mobile-JOR.png'}
                        alt='About iMile Delivery'
                        objectFit='fill'
                        loading='lazy'
                        placeholder='blurred'
                        className='bg-img1'
                      />
                    )} */}
                  </>
                )}
                {index === 4 && (
                  <>
                    {isPc ? (
                      <StaticImage
                        src={'../../../static/images/logisticsProduct/Product-OMN.png'}
                        alt='About iMile Delivery'
                        objectFit='fill'
                        loading='lazy'
                        placeholder='blurred'
                        className='bg-img1'
                      />
                    ) : (
                      <img
                        src={
                          'https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/Product-Mobile-OMN.png'
                        }
                        alt='About iMile Delivery'
                        className='bg-img1'
                      />
                    )}
                    {/* {isPc ? (
                      <StaticImage
                        src={'../../../static/images/logisticsProduct/Product-OMN.png'}
                        alt='About iMile Delivery'
                        objectFit='fill'
                        loading='lazy'
                        placeholder='blurred'
                        className='bg-img1'
                      />
                    ) : (
                      <StaticImage
                        src={'../../../static/images/logisticsProduct/Product-Mobile-OMN.png'}
                        alt='About iMile Delivery'
                        objectFit='fill'
                        loading='lazy'
                        placeholder='blurred'
                        className='bg-img1'
                      />
                    )} */}
                  </>
                )}
                {index === 5 && (
                  <>
                    {isPc ? (
                      <StaticImage
                        src={'../../../static/images/logisticsProduct/Product-KWT.png'}
                        alt='About iMile Delivery'
                        objectFit='fill'
                        loading='lazy'
                        placeholder='blurred'
                        className='bg-img1'
                      />
                    ) : (
                      <img
                        src={
                          'https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/Product-Mobile-KWT.png'
                        }
                        alt='About iMile Delivery'
                        className='bg-img1'
                      />
                    )}
                    {/* {isPc ? (
                      <StaticImage
                        src={'../../../static/images/logisticsProduct/Product-KWT.png'}
                        alt='About iMile Delivery'
                        objectFit='fill'
                        loading='lazy'
                        placeholder='blurred'
                        className='bg-img1'
                      />
                    ) : (
                      <StaticImage
                        src={'../../../static/images/logisticsProduct/Product-Mobile-KWT.png'}
                        alt='About iMile Delivery'
                        objectFit='fill'
                        loading='lazy'
                        placeholder='blurred'
                        className='bg-img1'
                      />
                    )} */}
                  </>
                )}
                {index === 6 && (
                  <>
                    {isPc ? (
                      <StaticImage
                        src={'../../../static/images/logisticsProduct/Product-QAT.png'}
                        alt='About iMile Delivery'
                        objectFit='fill'
                        loading='lazy'
                        placeholder='blurred'
                        className='bg-img1'
                      />
                    ) : (
                      <img
                        src={
                          'https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/Product-Mobile-QAT.png'
                        }
                        alt='About iMile Delivery'
                        className='bg-img1'
                      />
                    )}

                    {/* {isPc ? (
                      <StaticImage
                        src={'../../../static/images/logisticsProduct/Product-QAT.png'}
                        alt='About iMile Delivery'
                        objectFit='fill'
                        loading='lazy'
                        placeholder='blurred'
                        className='bg-img1'
                      />
                    ) : (
                      <StaticImage
                        src={'../../../static/images/logisticsProduct/Product-Mobile-QAT.png'}
                        alt='About iMile Delivery'
                        objectFit='fill'
                        loading='lazy'
                        placeholder='blurred'
                        className='bg-img1'
                      />
                    )} */}
                  </>
                )}
                {index === 7 && (
                  <>
                    {isPc ? (
                      <StaticImage
                        src={'../../../static/images/logisticsProduct/Product-BHR.png'}
                        alt='About iMile Delivery'
                        objectFit='fill'
                        loading='lazy'
                        placeholder='blurred'
                        className='bg-img1'
                      />
                    ) : (
                      <img
                        src={
                          'https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/Product-Mobile-BHR.png'
                        }
                        alt='About iMile Delivery'
                        className='bg-img1'
                      />
                    )}
                    {/* {isPc ? (
                      <StaticImage
                        src={'../../../static/images/logisticsProduct/Product-BHR.png'}
                        alt='About iMile Delivery'
                        objectFit='fill'
                        loading='lazy'
                        placeholder='blurred'
                        className='bg-img1'
                      />
                    ) : (
                      <StaticImage
                        src={'../../../static/images/logisticsProduct/Product-Mobile-BHR.png'}
                        alt='About iMile Delivery'
                        objectFit='fill'
                        loading='lazy'
                        placeholder='blurred'
                        className='bg-img1'
                      />
                    )} */}
                  </>
                )}
                <div className='country'>
                  <img
                    src={
                      require(`../../../static/images/logisticsProduct/Country-${item.country}.png`)
                        .default
                    }
                    alt=''
                    className='country-img'
                  />
                  <div className='country-name'>{item.countryName}</div>
                </div>
                <div className='country-desc'>{item.countryDesc}</div>
                <div className='read-btn'>
                  <Button size='small' type='text' style={{ color: '#095ef7', fontWeight: 600 }}>
                    {pageLocale.ReadMore}
                  </Button>
                </div>
              </Box>
            </Grid>
          )
        })}
      </Grid>
    )
  }

  return (
    <>
      <SEO
        title={seoLocale.logisticsProducts.title}
        description={seoLocale.logisticsProducts.description}
        pageLocale={pageLocale}
      />
      <Header
        location={props.location}
        headerLocale={JSON.parse(props.data.headerLocale.data)}
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />

      {/* banner */}
      <Box
        className='banner'
        sx={{
          position: 'relative',
          '.bg-img': {
            width: 1,
            minHeight: ['100vh', '700px'],
            maxHeight: ['100vh', '900px'],
            height: ['100vh', '100vh', '650px', '700px', '90vh'],
            mt: ['0', '55px', '90px'],
          },
        }}
      >
        {isPc ? (
          <img
            src='https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/logisticsProduct-banner.png'
            alt='About iMile Delivery'
            className='bg-img'
          />
        ) : (
          <img
            src='https://imile-minio-cdn.imile.com/imile-home/offical-web/homepage/logisticsProduct-banner_mobile.png'
            alt='About iMile Delivery'
            className='bg-img'
          />
        )}
        {/* {isPc ? (
          <StaticImage
            src='../../../static/images/logisticsProduct/logisticsProduct-banner.png'
            alt='About iMile Delivery'
            objectFit='cover'
            loading='lazy'
            placeholder='blurred'
            className='bg-img'
          />
        ) : (
          <StaticImage
            src='../../../static/images/logisticsProduct/logisticsProduct-banner_mobile.png'
            alt='About iMile Delivery'
            objectFit='cover'
            loading='lazy'
            placeholder='blurred'
            className='bg-img'
          />
        )} */}
        <Box
          sx={{
            position: 'absolute',
            top: ['25%', '75%', '25%', '32%'],
            transform: 'translate(0, -40%)',
            right: ['16px', '34px', '80px'],
            color: '#fff',
            textAlign: 'right',
            '.tit': {
              fontSize: [24, 30, 35, 35, 40],
              fontWeight: 600,
              mb: '10px',
              whiteSpace: 'pre-line',
            },
            '.desc': {
              fontSize: [14, 18, 22, 22, 28],
              whiteSpace: 'pre-line',
            },
          }}
        >
          <h1 className='tit'>{pageLocale.Slogan}</h1>
          <div className='desc'>{pageLocale.SloganDesc}</div>
        </Box>
      </Box>

      {/* Logistics Products */}
      <Box
        sx={{
          px: 'var(--page-px)',
          maxWidth: 'var(--page-width)',
          margin: '0 auto',
          color: '#5b5c73',
        }}
      >
        <Title title={pageLocale.title} component='h2' />
        <Movement />
      </Box>

      <Footer
        footerLocale={JSON.parse(props.data.footerLocale.data)}
        languageLocale={JSON.parse(props.data.languageLocale.data)}
      />
    </>
  )
}

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { ns: { in: ["logisticsProducts"] }, language: { eq: $language } }) {
      edges {
        node {
          ns
          data
        }
      }
    }
    headerLocale: locale(ns: { eq: "header" }, language: { eq: $language }) {
      data
    }
    footerLocale: locale(ns: { eq: "footer" }, language: { eq: $language }) {
      data
    }
    languageLocale: locale(ns: { eq: "language" }, language: { eq: $language }) {
      data
    }
    seoLocale: locale(ns: { eq: "seo" }, language: { eq: $language }) {
      data
    }
  }
`
